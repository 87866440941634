<template>
  <div class="w-100">
    <h4>{{$t('message.InsuranceReports')}}</h4>

    <b-card>
      <b-row>
        <b-col md="3">
          <v-select :options="paymentReport" label="name" v-model="filters.branch_id" ></v-select>
        </b-col>
        <b-col md="3">
          <input type="date" class="form-control">
        </b-col>
        <b-col md="3">
          <input type="date" class="form-control">
        </b-col>
      </b-row>
    </b-card>
    <b-card class="mt-3">
      <div v-for="(val,index) in reportsData" :key="index" class="w-100">
        <div class="w-100">
          <h3>{{val.branch}}</h3>
        </div>
          <vue-apex-charts class="w-75" type="bar" :options="getOptions(val.data.labels)" :series="[{name:val.branch,data:val.data.series}]" />
      </div>
    </b-card>
  </div>
</template>

<script>
import { getBranches } from '@/api/branchs/branchs'
import vSelect from 'vue-select'
import VueApexCharts from 'vue-apexcharts'
import flatPickr from 'vue-flatpickr-component'
import apexChatData from './apexChartData'
import { getBranchClientReports } from '@/api/reports/branch'
import ClientsByDate from '@/views/reports/components/ClientsByDate'
import { getPaymentReports } from '@/api/reports/payments'

const chartColors = {
  column: {
    series1: '#826af9',
    series2: '#d2b0ff',
    bg: '#f8d3ff',
  },
  success: {
    shade_100: '#7eefc7',
    shade_200: '#06774f',
  },
  donut: {
    series1: '#ffe700',
    series2: '#00d4bd',
    series3: '#826bf8',
    series4: '#2b9bf4',
    series5: '#FFA1A1',
  },
  area: {
    series3: '#a4f8cd',
    series2: '#60f2ca',
    series1: '#2bdac7',
  },
}

export default {
  name: 'PaymentReport',
  components: {
    ClientsByDate,
    vSelect,
    flatPickr,
    VueApexCharts,
  },
  data() {
    return {
      paymentReport:[],
      branchClients: [],
      apexChatData,
      rangePicker: ['2019-05-01', '2019-05-10'],
      fields: [
        {key: 'branch.name', label: this.$t('message.Branch')},
        {key: 'total', label: this.$t('message.Total')},
      ],
      filters: {
        branch_id: null,
      },
      options: {
        chart: {
          id: 'vuechart-example'
        },
        xaxis: {
          categories: [2018, 2019, 2020, 2021, 2022]
        }
      },
      series: [{
        name: 'series-1',
        data: [50, 40, 55, 60, 29,]
      }],
      reportsData: [],
    }
  },
  created() {
    this.fetchBranchs();
    this.clientReports()
  },
  methods: {
    fetchBranchs(){
      getBranches().then(res => {
        this.branchs = res.data.data;
      })
    },
    clientReports() {
      getPaymentReports().then(res => {
        this.reportsData = res.data.data
      })
    },
    getOptions(labels) {
      return  {
        chart: {
          id: 'vuechart-example'
        },
        xaxis: {
          categories: labels
        }
      };
    }
  }
}
</script>

<style scoped>

</style>
